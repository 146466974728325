const checkBiometricType = (methods: string[]) => {
  try {
    let res: string;
    if (methods.includes("finger") && methods.includes("face")) {
      res = "both";
    } else if (methods.includes("finger")) {
      res = "finger";
    } else if (methods.includes("face")) {
      res = "face";
    } else {
      res = "";
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export { checkBiometricType };